import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddLoyaltyProgramModal from "../modal/AddLoyaltyProgramModal";
import {
  addLoyaltyProgramApi,
  getStoreDetailsApi,
  editLoyaltyProgramApi,
  addItemApi,
  deleteProgramApi,
} from "../../services/Store/StoreServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import gift from "../../images/image 2 (1).png";
import moment from "moment";
import StoreDetailsSkeleton from "../components/SkeletonPages/StoreDetailsSkeleton";
import CreateRewardsTier from "../modal/CreateRewardsTier";
import SilverIcon from "../../images/silver-cup 1.png";
import GoldIcon from "../../images/award-3-gold 1.png";
import DiamondIcon from "../../images/diamond (11) 1.png";
import PlatinumIcon from "../../images/award 1.png";
import LineIcon from "../../images/Line 59.svg";
import toast from "react-hot-toast";
import CreateItemModal from "../modal/CreateItemModal";
import { storeIdAction } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../modal/ConfirmationModal";

export default function StoresDetails(props) {
  const { t } = useTranslation();
  const storeId = props.location?.state;
  const [addProgramModal, setAddProgramModal] = useState(false);
  const [editProgramModal, setEditProgramModal] = useState(false);
  const [addTierModal, setAddTierModal] = useState(false);
  const [editTierModal, setEditTierModal] = useState(false);
  const [addItemModal, setAddItemModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState([]);
  const [deleteProgramModal, setDeleteProgramModal] = useState(false);
  const dispatch = useDispatch();
  dispatch(storeIdAction(storeId));
  async function getStoreDetails() {
    setLoader(true);
    try {
      const response = await getStoreDetailsApi(storeId);
      setDetails(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getStoreDetails();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between pb-3">
        <div className="">
          <button
            className="btn btn-sm back-btn"
            onClick={() => props.history.push("/store-management")}
          >
            {t("store_details.actions.back")}
          </button>
        </div>
        <div
          className="d-flex flex-wrap justify-content-end"
          style={{ gap: "10px" }}
        >
          {!details?.tier && (
            <button
              className="btn btn-primary btn-sm "
              onClick={() => setAddTierModal(true)}
            >
              {t("store_details.actions.addRewardsTier")}
            </button>
          )}
          {!details?.loyaltyProgram?.name && (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setAddProgramModal(true)}
            >
              {t("store_details.actions.addProgram")}
            </button>
          )}
        </div>
      </div>
      {loader ? (
        <StoreDetailsSkeleton />
      ) : (
        <div>
          <div className="d-flex store-card" style={{ gap: "20px" }}>
            <div className="profile-card">
              <Card>
                <div className="d-block p-3">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "1rem" }}
                  >
                    <div>
                      <img
                        src={`https://api.paybagapp.com/${details?.image}`}
                        width={100}
                        style={{ borderRadius: "10PX" }}
                        height={100}
                        className="text-center"
                      />
                    </div>
                    <div className="text-left" style={{ flex: "1" }}>
                      <h3 className="text-color ">{details?.storeName}</h3>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">
                          {t("store_details.labels.createdDate")}
                        </h5>
                        <p className="m-0">
                          {" "}
                          {moment(details?.createdAt).format("ll")}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <h5 style={{ margin: "0" }}>
                          {t("store_details.labels.phone")}
                        </h5>
                        <p style={{ margin: "0" }}>
                          +{details?.countryCode} {details?.contactNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="loyalty-program">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ borderBottom: "1px solid  #8b8484", padding: "8px" }}
                >
                  <label className="pl-2 pt-2">
                    {t("store_details.labels.loyaltyPrograms")}
                  </label>
                  {details?.loyaltyProgram && (
                    // <div
                    //   class="btn btn-primary shadow btn-xs sharp list-btn"
                    //   style={{ cursor: "pointer" }}
                    //   onClick={() => {
                    //     setEditProgramModal(true);
                    //   }}
                    // >
                    //   <FontAwesomeIcon icon={faPenToSquare} />{" "}
                    // </div>
                    <Dropdown>
                      <Dropdown.Toggle className="offer-action-btn">
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ minWidth: "6rem" }}>
                        <Dropdown.Item className="offer-acton-options">
                          <div
                            class="btn btn-primary shadow btn-xs sharp list-btn"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditProgramModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />{" "}
                          </div>
                          <div
                            class="btn btn-danger  btn-xs sharp list-btn"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => {
                              setDeleteProgramModal(true);
                              // setOfferItem(item);
                            }}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                {details?.loyaltyProgram?.name ? (
                  <div
                    className="loyalty-program-card"
                    onClick={() =>
                      props.history.push({
                        pathname: "/program-offers",
                        state: details?.loyaltyProgram,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="program-card">
                      <div className="card-img">
                        <img src={gift} />
                      </div>
                      <div>
                        <h4>{details?.loyaltyProgram?.name}</h4>
                        <p className="text-left">
                          {details?.loyaltyProgram?.title.length > 45
                            ? details?.loyaltyProgram?.title.slice(0, 40) +
                              "..."
                            : details?.loyaltyProgram?.title}
                        </p>
                      </div>
                    </div>
                    <p className="text-left">
                      {details?.loyaltyProgram?.description}
                    </p>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "120px" }}
                  >
                    <p style={{ color: "white" }}>{t("common.empty_table")}</p>
                  </div>
                )}
              </Card>
            </div>
            <Card
              className="tier-card"
              style={{
                background: "#fff",
                color: "#000",
                transform: "none",
              }}
            >
              <div className="d-flex border-bottom align-items-center justify-content-between">
                <label className="pl-3 pt-2">
                  {t("store_details.labels.tier")}
                </label>
                <div
                  class="btn btn-primary shadow btn-xs sharp list-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditTierModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />{" "}
                </div>
              </div>
              <div className="p-3">
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src={SilverIcon}
                    style={{
                      objectFit: "cover",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <div>
                    <strong>{t("store_details.tier.silverStatus")}</strong>
                    <p className="m-0">{details?.tier?.silver}</p>
                  </div>
                </div>
                <div>
                  <img
                    src={LineIcon}
                    alt="jd"
                    style={{ width: "40px", height: "50px" }}
                  />
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src={GoldIcon}
                    style={{
                      objectFit: "cover",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <div>
                    <strong>{t("store_details.tier.goldStatus")}</strong>
                    <p className="m-0">{details?.tier?.gold}</p>
                  </div>
                </div>

                <div>
                  <img
                    src={LineIcon}
                    alt="jd"
                    style={{ width: "40px", height: "50px" }}
                  />
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src={PlatinumIcon}
                    style={{
                      objectFit: "cover",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <div>
                    <strong>{t("store_details.tier.platinumStatus")}</strong>
                    <p className="m-0">{details?.tier?.platinum}</p>
                  </div>
                </div>
                <div>
                  <img
                    src={LineIcon}
                    alt="jd"
                    style={{ width: "40px", height: "50px" }}
                  />
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src={DiamondIcon}
                    style={{
                      objectFit: "cover",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <div>
                    <strong>{t("store_details.tier.diamondStatus")}</strong>
                    <p className="m-0">{details?.tier?.diamond}</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <Card>
            <div className="p-4">
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <h5 className="m-0">{t("common.open_time")}</h5>
                <p className="m-0">
                  {" "}
                  {moment(details?.openTime, "HH:mm:ss").format("hh:mm A")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <h5 className="m-0">{t("common.close_time")}</h5>
                <p className="m-0">
                  {moment(details?.closeTime, "HH:mm:ss").format("hh:mm A")}
                </p>
              </div>
              <div className="flex-details border-bottom">
                <h5 className="m-0">{t("store_details.labels.address")}</h5>
                <p
                  className="m-0"
                  style={{
                    textAlign: "start",
                    maxWidth: "70%",
                    wordBreak: "normal",
                  }}
                >
                  {details?.address}
                </p>
              </div>
              <div className="flex-details border-bottom">
                <h5 className="m-0">{t("common.description")}</h5>
                <p
                  className="m-0 "
                  style={{
                    textAlign: "start",
                    wordBreak: "normal",
                    maxWidth: "70%",
                  }}
                >
                  {details?.description}
                </p>
              </div>
            </div>
          </Card>
        </div>
      )}
      {addProgramModal && (
        <AddLoyaltyProgramModal
          show={addProgramModal}
          onHide={() => setAddProgramModal(false)}
          title={t("store_details.actions.addProgram")}
          successMessage={t(
            "toast_messages.add_loyalty_program_success_message"
          )}
          api={addLoyaltyProgramApi}
          storeId={details?.id}
        />
      )}
      {addTierModal && (
        <CreateRewardsTier
          title={t("create_edit_tier_form.add_form_title")}
          show={addTierModal}
          onHide={() => setAddTierModal(false)}
          storeId={storeId}
          getStoreDetails={getStoreDetails}
        />
      )}
      {editTierModal && (
        <CreateRewardsTier
          title={t("create_edit_tier_form.edit_form_title")}
          show={editTierModal}
          onHide={() => setEditTierModal(false)}
          storeId={storeId}
          data={details?.tier}
        />
      )}
      {editProgramModal && (
        <AddLoyaltyProgramModal
          show={editProgramModal}
          onHide={() => setEditProgramModal(false)}
          title={t("store_details.labels.edit_program")}
          successMessage={t(
            "toast_messages.edit_loyalty_program_success_message"
          )}
          api={editLoyaltyProgramApi}
          programData={details?.loyaltyProgram}
        />
      )}
      {addItemModal && (
        <CreateItemModal
          show={addItemModal}
          handleClose={() => setAddItemModal(false)}
          api={addItemApi}
          successMessage={t("toast_messages.create_item_success_message")}
          storeId={storeId}
        />
      )}
      {deleteProgramModal && (
        <ConfirmationModal
          show={deleteProgramModal}
          onHide={() => setDeleteProgramModal(false)}
          successMessage={`${t(
            "toast_messages.delete_program_success_message"
          )}`}
          api={deleteProgramApi}
          refresh={getStoreDetails}
          content={`${t("common.delete_popup_message")}`}
          data={details?.loyaltyProgram}
        />
      )}
    </div>
  );
}
